import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { MENU } from 'src/app/views/layout/navbar/menu';
import { CoreComponent } from '../base/component/core.component';
import { NullOrEmptyController, ProjectSettings, UserTokenDto } from '../base/dto/core.dto';
import { UserService } from '../base/service/user/user.service';

@Injectable()
export class AuthGuard extends CoreComponent implements CanActivate {

  constructor(userService: UserService, cookieService: CookieService, router: Router, route: ActivatedRoute) {
    super(userService, cookieService, route, router, null);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let user: UserTokenDto = <UserTokenDto>this.cookieService.getObject(ProjectSettings.getInfo().tokenName);

    if (!NullOrEmptyController.controll(user) && !NullOrEmptyController.controll(user.access_token)) {
      let isRoute: boolean = false;
      MENU.map(element => {
        if (element.link == state.url) {
          let isRole = element.roles.find(x => x == user.user_type);
          if (!NullOrEmptyController.controll(isRole)) {
            isRoute = true;
          }
        }
      });
      return isRoute;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}