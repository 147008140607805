export class CompanyDto {
  public constructor(init?: Partial<CompanyDto>) {
    Object.assign(this, init);
  }
  Id: string;
  Title: string;
  Summary: string;
  Description: string;
  Latitude: string;
  Longitude: string;
  Phone: string;

  IsActive: boolean;
  CoverPhoto: string;
  CategoryId: string;

  Category: CompanyCategoryDto = new CompanyCategoryDto();
  Photos: Array<string> = new Array<string>();
  Discounts: Array<CompanyDiscountDto> = new Array<CompanyDiscountDto>();
  UserId: string;
  Author: string;
  Is_sodexo_or_multinet_discount: any;
  Is_home_service_discount: any;
  AuthorPhone: string;
  Discount: number;
  DiscountEndDte: Date;
  CreatedAt: Date;
}

export class CompanyCategoryDto {
  public constructor(init?: Partial<CompanyCategoryDto>) {
    Object.assign(this, init);
  }
  Id: string;
  Title: string;
  Summary: string;
  Order: number;
  Icon: string;
  IsActive: boolean;
  ParentId: string;
}

export enum DiscountType {
  /**
   * Tutar Bazında İndirim
   */
  Amount = 1,
  /**
   * Yüzde Bazında İndirim
   */
  Percentage = 2,
}

export class DiscountTypeDto {
  type: DiscountType;
  name: string;
}

export class CompanyDiscountDto {
  Id: string;
  Code: string;
  Title: string;
  Description: string;

  Discount: number;
  DiscountType: DiscountType;
  _DiscountType: string;

  Amount: number;
  DiscountedPrice: number;

  StartDate: Date;
  EndDate: Date;
  CompanyId: string;
}

export class MonthlyDto {
  Count: number;
  Month: number;
}
