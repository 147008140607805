import {
  CompanyCategoryDto,
  CompanyDto,
  DiscountType,
  DiscountTypeDto,
} from "./company/company.dto";
import { GuideCategoryDto, GuideDto } from "./guide/guide.dto";
import {
  SchoolDepartmentDto,
  SchoolDto,
  SchoolFacultyDto,
} from "./school/school.dto";
import { UserDto } from "./user/user.dto";

export class ApiResult<T> {
  isSuccess: boolean;
  messages: Array<string> = new Array<string>();
  data: T;
}

export class ProjectSettings {
  public static getInfo(): ProjectSettingsDto {
    let activeHostName: string = window.location.hostname;
    let response: ProjectSettingsDto = new ProjectSettingsDto();
    response.projectVerison = "1.0.3";
    response.projectKey = "QWxhbnlhR2VuY2xpayoyMA==";
    response.tokenName = btoa("alanya_bel_usr_" + response.projectVerison);

    if (activeHostName == "localhost") {
      response.fileBaseUrl = "http://192.168.1.115:54555/";
      response.apiUrl = "http://192.168.1.115:45455/api/";

      // response.apiUrl = "https://kampusalanyaapi.tulparyazilim.com.tr/api/";
      // response.fileBaseUrl = "https://kampusalanyaapi.tulparyazilim.com.tr/";

      // response.apiUrl = "http://88.255.194.86:8407/api/";
      // response.fileBaseUrl = "http://88.255.194.86:8407/";
    } else {
      response.apiUrl = "https://kampusalanyaapi.tulparyazilim.com.tr/api/";
      response.fileBaseUrl = "https://kampusalanyaapi.tulparyazilim.com.tr/";

      // response.apiUrl = "http://88.255.194.86:8407/api/";
      // response.fileBaseUrl = "http://88.255.194.86:8407/";
    }
    return response;
  }
}
export class ProjectSettingsDto {
  apiUrl: string;
  fileBaseUrl: string;
  projectVerison: string;
  tokenName: string;
  projectKey: string;
}

export class NullOrEmptyController {
  /**
   * Kontrol edilen nesnenin içi boş ise 'true' değilse ise 'false' değeri döner.
   * @param data
   */
  public static controll(data): boolean {
    if (typeof data == "number" || typeof data == "boolean") {
      return false;
    }
    if (typeof data == "undefined" || data === null || data == "") {
      return true;
    }
    if (typeof data.length != "undefined") {
      return data.length == 0;
    }
    var count = 0;
    for (var i in data) {
      if (data.hasOwnProperty(i)) {
        count++;
      }
    }
    return count == 0;
  }
}

export class UserTokenDto {
  access_token: string;
  user_name: string;
  user_type: string;
  user_id: string;
  token_type: string;
  expires_in: Date;
  refresh_token: string;
}

export class CoreGlobalVariable {
  //#region Company
  public static companyCategories: Array<CompanyCategoryDto> =
    Array<CompanyCategoryDto>();
  public static companies: Array<CompanyDto> = new Array<CompanyDto>();
  public static discountTypes: Array<DiscountTypeDto> = [
    { type: DiscountType.Amount, name: "Tutar" },
    { type: DiscountType.Percentage, name: "Yüzde" },
  ];
  //#endregion

  //#region Guide
  public static guides: Array<GuideDto> = new Array<GuideDto>();
  public static guideCategories: Array<GuideCategoryDto> =
    new Array<GuideCategoryDto>();
  //#endregion

  //#region School
  public static schools: Array<SchoolDto> = new Array<SchoolDto>();
  public static schoolDepartments: Array<SchoolDepartmentDto> =
    new Array<SchoolDepartmentDto>();
  public static schoolFaculties: Array<SchoolFacultyDto> =
    new Array<SchoolFacultyDto>();
  public static students: Array<UserDto> = new Array<UserDto>();
  //#endregion

  //#region  Users
  public static users: Array<UserDto> = new Array<UserDto>();
  //#endregion

  public static userInformation: UserTokenDto = new UserTokenDto();
}
