import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { CookieModule } from "ngx-cookie";
import { HIGHLIGHT_OPTIONS } from "ngx-highlightjs";
import { TreeModule } from "primeng/tree";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BusRouteListDirectiveModule } from "./core/base/directive/bus-route-list-directive/bus-route-list.directive";
import { BusRouteSaveDirectiveModule } from "./core/base/directive/bus-route-save-directive/bus-route-save.directive";
import { BusSaveDirectiveModule } from "./core/base/directive/bus-save-directive/bus-save.directive";
import { CompanyCategoryListDirectiveModule } from "./core/base/directive/company-category-list-directive/company-category-list.directive";
import { CompanyCategorySaveDirectiveModule } from "./core/base/directive/company-category-save-directive/company-category-save.directive";
import { CompanySaveDirectiveModule } from "./core/base/directive/company-save-directive/company-save.directive";
import { GuideCategoryListDirectiveModule } from "./core/base/directive/guide-category-list-directive/guide-category-list.directive";
import { GuideCategorySaveDirectiveModule } from "./core/base/directive/guide-category-save-directive/guide-category-save.directive";
import { GuideSaveDirectiveModule } from "./core/base/directive/guide-save-directive/guide-save.directive";
import { NewsSaveDirectiveModule } from "./core/base/directive/news-save-directive/news-save.directive";
import { NotificationSaveDirectiveModule } from "./core/base/directive/notification-save-directive/notification-save.directive";
import { SchoolListDirectiveModule } from "./core/base/directive/school-list-directive/school-list.directive.module";
import { SchoolSaveDirectiveModule } from "./core/base/directive/school-save-directive/school-save.directive";
import { UserSaveDirectiveModule } from "./core/base/directive/user-save-directive/user-save.directive";
import { UserService } from "./core/base/service/user/user.service";
import { AuthGuard } from "./core/guard/auth.guard";
import { LayoutModule } from "./views/layout/layout.module";
import { ErrorPageComponent } from "./views/pages/error-page/error-page.component";

@NgModule({
  declarations: [AppComponent, ErrorPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    SweetAlert2Module.forRoot(),
    CookieModule.forRoot(),
    TreeModule,

    //#region Directive

    // CompanySaveDirectiveModule,
    // CompanyCategorySaveDirectiveModule,
    // CompanyCategoryListDirectiveModule,

    // GuideSaveDirectiveModule,
    // GuideCategorySaveDirectiveModule,
    // GuideCategoryListDirectiveModule,

    // SchoolListDirectiveModule,
    // SchoolSaveDirectiveModule,

    // UserSaveDirectiveModule,
    // NewsSaveDirectiveModule,
    // NotificationSaveDirectiveModule,
    // BusSaveDirectiveModule,
    // BusRouteListDirectiveModule,
    // BusRouteSaveDirectiveModule,
    //#endregion
  ],
  providers: [
    AuthGuard,
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import("highlight.js/lib/core"),
        languages: {
          xml: () => import("highlight.js/lib/languages/xml"),
          typescript: () => import("highlight.js/lib/languages/typescript"),
          scss: () => import("highlight.js/lib/languages/scss"),
        },
      },
    },
    UserService,
  ],
  exports: [
    //#region Directive
    // CompanySaveDirectiveModule,
    // CompanyCategorySaveDirectiveModule,
    // CompanyCategoryListDirectiveModule,
    // GuideSaveDirectiveModule,
    // GuideCategorySaveDirectiveModule,
    // GuideCategoryListDirectiveModule,
    // SchoolListDirectiveModule,
    // SchoolSaveDirectiveModule,
    // UserSaveDirectiveModule,
    // NewsSaveDirectiveModule,
    // NotificationSaveDirectiveModule,
    // BusSaveDirectiveModule,
    // BusRouteListDirectiveModule,
    // BusRouteSaveDirectiveModule,
    //#endregion
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
