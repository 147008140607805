import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { CoreService } from '../core.service';

@Injectable()
export class UserService extends CoreService {

        constructor(http: HttpClient, cookieService: CookieService, router: Router) {
                super(http, cookieService, "user", router);
        }


}