import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Anasayfa',
    icon: 'home',
    link: '/dashboard',
    roles: ["SystemAdmin", "Editor", "StudentSealer"]
  },
  {
    label: 'Anasayfa',
    icon: 'home',
    link: '/companies/detail',
    roles: ["Company"]
  },
  {
    label: 'İşletmeler',
    icon: 'map-pin',
    link: 'companies/list',
    roles: ["SystemAdmin", "Editor", "StudentSealer"]
  },
  {
    label: 'Şehir Rehberi',
    icon: 'map',
    link: '/guide/list',
    roles: ["SystemAdmin", "Editor", "StudentSealer"]
  },
  {
    label: 'Anket',
    icon: 'clipboard',
    link: '/survey/list',
    roles: ["SystemAdmin", "Editor", "StudentSealer"]
  },
  {
    label: 'Öğrenciler',
    icon: 'users',
    link: '/students/list',
    roles: ["SystemAdmin", "Editor", "StudentSealer"]
  },
  {
    label: 'Otobüs',
    icon: 'navigation-2',
    link: '/bus',
    roles: ["SystemAdmin", "Editor", "StudentSealer"]
  },
  {
    label: 'Bildirim',
    icon: 'bell',
    link: '/notification/list',
    roles: ["SystemAdmin", "Editor", "StudentSealer"]
  },
  {
    label: 'Haberler',
    icon: 'bookmark',
    link: '/news/list',
    roles: ["SystemAdmin", "Editor", "StudentSealer"]
  },
  {
    label: 'İş Başvuruları',
    icon: 'briefcase',
    link: '/jobapplication/list',
    roles: ["SystemAdmin", "Editor", "StudentSealer"]
  },


  {
    label: 'Kullanıcılar',
    icon: 'users',
    link: '/user/list',
    roles: ["SystemAdmin", "Editor", "StudentSealer"]
  },
];
