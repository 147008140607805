import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { CoreComponent } from 'src/app/core/base/component/core.component';
import { UserService } from 'src/app/core/base/service/user/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends CoreComponent {

  constructor(userService: UserService, cookieService: CookieService, router: Router, route: ActivatedRoute, changeDetectorRef: ChangeDetectorRef) {
    super(userService, cookieService, route, router, changeDetectorRef);
  }


}
