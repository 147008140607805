import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { CoreComponent } from 'src/app/core/base/component/core.component';
import { CoreGlobalVariable, NullOrEmptyController, ProjectSettings, UserTokenDto } from 'src/app/core/base/dto/core.dto';
import { UserService } from 'src/app/core/base/service/user/user.service';
import { MENU } from './menu';
import { MenuItem } from './menu.model';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends CoreComponent {

  menuItems = [];

  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2, userService: UserService, cookieService: CookieService, router: Router, route: ActivatedRoute, changeDetectorRef: ChangeDetectorRef) {
    super(userService, cookieService, route, router, changeDetectorRef);
  }

  /**
  * Fixed header menu on scroll
  */
  @HostListener('window:scroll', ['$event']) getScrollHeight(event) {
    if (window.matchMedia('(min-width: 992px)').matches) {
      let header: HTMLElement = document.querySelector('.horizontal-menu') as HTMLElement;
      if (window.pageYOffset >= 100) {
        header.classList.add('fixed-on-scroll');
      } else {
        header.classList.remove('fixed-on-scroll');
      }
    }
  }



  ngOnInit(): void {
    // this.menuItems = MENU;
    let _user = <UserTokenDto>this.cookieService.getObject(ProjectSettings.getInfo().tokenName);
    MENU.map(element => {
      let isRole = element.roles.find(x => x == _user.user_type);

      if (!NullOrEmptyController.controll(isRole)) {
        this.menuItems.push(element);
      }
    });

    /**
    * closing the header menu after route change in tablet/mobile devices
    */
    if (window.matchMedia('(max-width: 991px)').matches) {
      this.router.events.forEach((event) => {
        if (event instanceof NavigationEnd) {
          document.querySelector('.horizontal-menu .bottom-navbar').classList.remove('header-toggled');
        }
      });
    }
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subMenus !== undefined ? item.subMenus.length > 0 : false;
  }

  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();
    this.cookieService.removeAll();
    CoreGlobalVariable.userInformation = new UserTokenDto();
    this.router.navigate(['/auth/login']);
  }

  /**
   * Toggle header menu in tablet/mobile devices
   */
  toggleHeaderMenu() {
    document.querySelector('.horizontal-menu .bottom-navbar').classList.toggle('header-toggled');
  }

}
