import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/guard/auth.guard";
import { BaseComponent } from "./views/layout/base/base.component";
import { ErrorPageComponent } from "./views/pages/error-page/error-page.component";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./views/pages/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "companies",
        loadChildren: () =>
          import("./views/pages/companies/companies.module").then(
            (m) => m.CompaniesModule
          ),
      },
      {
        path: "guide",
        loadChildren: () =>
          import("./views/pages/guide/guide.module").then((m) => m.GuideModule),
      },
      {
        path: "students",
        loadChildren: () =>
          import("./views/pages/students/students.module").then(
            (m) => m.StudentsModule
          ),
      },
      {
        path: "survey",
        loadChildren: () =>
          import("./views/pages/survey/suervey.module").then(
            (m) => m.SurveyModule
          ),
      },
      {
        path: "news",
        loadChildren: () =>
          import("./views/pages/news/news.module").then((m) => m.NewsModule),
      },
      {
        path: "notification",
        loadChildren: () =>
          import("./views/pages/notification/notification.module").then(
            (m) => m.NotificationModule
          ),
      },
      {
        path: "bus",
        loadChildren: () =>
          import("./views/pages/bus/bus.module").then((m) => m.BusModule),
      },
      {
        path: "jobapplication",
        loadChildren: () =>
          import("./views/pages/job-application/job-application.module").then(
            (m) => m.JobApplicationModule
          ),
      },

      {
        path: "user",
        loadChildren: () =>
          import("./views/pages/user/users.module").then((m) => m.UsersModule),
      },
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ],
  },
  {
    path: "error",
    component: ErrorPageComponent,
    data: {
      type: 404,
      title: "Page Not Found",
      desc: "Oopps!! The page you were looking for doesn't exist.",
    },
  },
  {
    path: "error/:type",
    component: ErrorPageComponent,
  },
  { path: "**", redirectTo: "error", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
