import { ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie";
import Swal, {
  SweetAlertIcon,
  SweetAlertOptions,
  SweetAlertPosition,
} from "sweetalert2";
import {
  CompanyCategoryDto,
  CompanyDto,
  DiscountTypeDto,
} from "../dto/company/company.dto";
import {
  CoreGlobalVariable,
  ProjectSettings,
  UserTokenDto,
} from "../dto/core.dto";
import {
  GuideCategoryDto,
  GuideCategoryTreeDto,
  GuideDto,
} from "../dto/guide/guide.dto";
import {
  SchoolDepartmentDto,
  SchoolDto,
  SchoolFacultyDto,
} from "../dto/school/school.dto";
import { UserDto } from "../dto/user/user.dto";
import { UserService } from "../service/user/user.service";

export class CoreComponent {
  appVersion: string = ProjectSettings.getInfo().projectVerison;
  fileBaseUrl: string = ProjectSettings.getInfo().fileBaseUrl;

  _userInformation: UserTokenDto = CoreGlobalVariable.userInformation;

  //#region Company
  _companies: Array<CompanyDto> = CoreGlobalVariable.companies;
  _companyCategories: Array<CompanyCategoryDto> =
    CoreGlobalVariable.companyCategories;
  _discountTypes: Array<DiscountTypeDto> = CoreGlobalVariable.discountTypes;
  //#endregion

  //#region Guide
  _guides: Array<GuideDto> = new Array<GuideDto>();
  _guideCategories: Array<GuideCategoryDto> = new Array<GuideCategoryDto>();
  _guideCategoriesTree: Array<GuideCategoryTreeDto> =
    new Array<GuideCategoryTreeDto>();
  //#endregion

  //#region School
  _schools: Array<SchoolDto> = new Array<SchoolDto>();
  _schoolDepartments: Array<SchoolDepartmentDto> =
    new Array<SchoolDepartmentDto>();
  _schoolFaculties: Array<SchoolFacultyDto> = new Array<SchoolFacultyDto>();
  _students: Array<UserDto> = new Array<UserDto>();

  //#endregion

  //#region User
  _users: Array<UserDto> = new Array<UserDto>();
  //#endregion

  constructor(
    public userService: UserService,
    public cookieService: CookieService,
    public route: ActivatedRoute,
    public router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {}

  //#region Swall
  async openSwall(
    title: string,
    icon: SweetAlertIcon = "success",
    position: SweetAlertPosition = "top-right",
    timer: number = 5000
  ) {
    await Swal.fire({
      toast: true,
      position: position,
      showConfirmButton: false,
      timer: timer,
      title: title,
      icon: icon,
    } as SweetAlertOptions);
  }

  async openDialogSwall(
    title: string,
    htlm: string,
    icon: SweetAlertIcon = "question",
    confirmText = "Sil"
  ) {
    let resultDialog = await Swal.fire({
      icon: icon,
      title: title,
      html: htlm,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:
        "<i class='feather icon-thumbs-up'></i> " + confirmText,
      cancelButtonText: "<i class='feather icon-thumbs-down'></i> Vazgeç",
    } as SweetAlertOptions);
    return resultDialog;
  }

  //#endregion

  //#region ChangeDetectorRef
  changeDetectorRefInit() {
    this.changeDetectorRef.detectChanges();
  }
  //#endregion
}
