import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Router } from "@angular/router";
import * as crypto from "crypto-js";
import { CookieService } from "ngx-cookie";
import {
  ApiResult,
  NullOrEmptyController,
  ProjectSettings,
  UserTokenDto,
} from "../dto/core.dto";

export abstract class CoreService {
  webApiUrl: string = ProjectSettings.getInfo().apiUrl;

  private key = crypto.enc.Utf8.parse("AlanyaGenclik*20");

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    protected serviceName: string,
    private router: Router
  ) {}

  /**
   * Http Get
   * @param url
   */
  public async get<T>(url: string): Promise<ApiResult<T>> {
    return new Promise(async (resolve) => {
      this.http
        .get(this.webApiUrl + this.serviceName + "/" + url, {
          headers: await this.headers(),
        })
        .subscribe(
          (response: any) => {
            response.data = this.decrypt(response.data);
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            let response: ApiResult<any> = new ApiResult<any>();
            response.isSuccess = false;
            response.messages = this.httpErrorHandle(error);
            if (error.status == 401) {
              this.cookieService.removeAll();
              this.router.navigate(["/auth/login"]);
            }
            resolve(response);
          }
        );
    });
  }

  /**
   * Http Post
   * @param url
   * @param data
   */
  public async post<T>(url: string, data: any): Promise<ApiResult<T>> {
    return new Promise(async (resolve) => {
      try {
        this.http
          .post(this.webApiUrl + this.serviceName + "/" + url, data, {
            headers: await this.headers(),
          })
          .subscribe(
            (response: any) => {
              response.data = this.decrypt(response.data);
              resolve(response);
            },
            (error: HttpErrorResponse) => {
              console.log(error);
              let response: ApiResult<any> = new ApiResult<any>();
              response.isSuccess = false;
              response.messages = this.httpErrorHandle(error);
              resolve(response);
            }
          );
      } catch (error) {
        let response: ApiResult<any> = new ApiResult<any>();
        response.isSuccess = false;
        response.messages.push(error.error);
      }
    });
  }

  /**
   * Http Put
   * @param url
   * @param data
   */
  public async put<T>(url: string, data: any): Promise<ApiResult<T>> {
    return new Promise(async (resolve) => {
      this.http
        .put(this.webApiUrl + this.serviceName + "/" + url, data, {
          headers: await this.headers(),
        })
        .subscribe(
          (response: any) => {
            response.data = this.decrypt(response.data);
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            let response: ApiResult<any> = new ApiResult<any>();
            response.isSuccess = false;
            response.messages = this.httpErrorHandle(error);
            resolve(response);
          }
        );
    });
  }

  /**
   * Http Delete
   * @param url
   */
  public async delete<T>(url: string): Promise<ApiResult<T>> {
    return new Promise(async (resolve) => {
      this.http
        .delete(this.webApiUrl + this.serviceName + "/" + url, {
          headers: await this.headers(),
        })
        .subscribe(
          (response: any) => {
            response.data = this.decrypt(response.data);
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            let response: ApiResult<any> = new ApiResult<any>();
            response.isSuccess = false;
            response.messages = this.httpErrorHandle(error);
            resolve(response);
          }
        );
    });
  }

  /**
   * Http Post-Upload
   * @param url
   * @param data
   */
  public async upload<T>(url: string, data: any): Promise<ApiResult<T>> {
    return new Promise(async (resolve) => {
      try {
        this.http
          .post(this.webApiUrl + this.serviceName + "/" + url, data, {
            headers: await this.headers(),
          })
          .subscribe(
            (response: any) => {
              response.data = this.decrypt(response.data);
              resolve(response);
            },
            (error: HttpErrorResponse) => {
              let response: ApiResult<any> = new ApiResult<any>();
              response.isSuccess = false;
              response.messages = this.httpErrorHandle(error);
              resolve(response);
            }
          );
      } catch (error) {
        let response: ApiResult<any> = new ApiResult<any>();
        response.isSuccess = false;
        response.messages.push(error.error);
      }
    });
  }

  //#region Crypto

  public decrypt<T>(data) {
    let decryptedData = crypto.AES.decrypt(data, this.key, {
      iv: this.key,
    }).toString(crypto.enc.Utf8);
    let decryptedDataParsed: T = JSON.parse(
      decodeURIComponent(escape(atob(decryptedData)))
    );
    return decryptedDataParsed;
  }

  private CryptoJSAesJson = {
    stringify: function (cipherParams) {
      let j: any = { ct: cipherParams.ciphertext.toString(crypto.enc.Base64) };
      if (cipherParams.iv) j.iv = cipherParams.iv.toString();
      if (cipherParams.salt) j.s = cipherParams.salt.toString();
      return JSON.stringify(j);
    },
    parse: function (jsonStr) {
      var j = JSON.parse(jsonStr);
      var cipherParams = crypto.lib.CipherParams.create({
        ciphertext: crypto.enc.Base64.parse(j.ct),
      });
      if (j.iv) cipherParams.iv = crypto.enc.Hex.parse(j.iv);
      if (j.s) cipherParams.salt = crypto.enc.Hex.parse(j.s);
      return cipherParams;
    },
  };
  //#endregion

  //#region Http Eroor Handle
  private httpErrorHandle(error): Array<string> {
    let messages: Array<string> = new Array<string>(); 
    messages = error?.error?.messages;
//     switch (error.status) {
      
//       case 404: {
//         messages.push(`Not Found: ${error.message}`);
//       }
//       case 403: {
//         messages.push(`Access Denied: ${error.message}`);
//       }
//       case 500: {
//         messages.push(`Internal Server Error: ${error.message}`);
//       }
//       default: {
//         messages.push(`Unknown Server Error: ${error.message}`);
//       }
//     }
    return messages;
  }
  //#endregion

  //#region Headers
  private async headers(isUpload: boolean = false): Promise<any> {
    let headers = new HttpHeaders();
    if (!isUpload) {
      headers = headers.append("Content-Type", "application/json");
    }
    let user: UserTokenDto = <UserTokenDto>(
      this.cookieService.getObject(ProjectSettings.getInfo().tokenName)
    );
    if (
      !NullOrEmptyController.controll(user) &&
      !NullOrEmptyController.controll(user.access_token)
    ) {
      headers = headers.append(
        "Authorization",
        user.token_type + " " + user.access_token
      );
    }
    return headers;
  }
  //#endregion
}
