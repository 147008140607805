import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { ProjectSettings } from './core/base/dto/core.dto';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Kampüs Alanya';
  constructor(private cookieService: CookieService) {
  }

  ngOnInit(): void {

    let currentVersion = window.localStorage.getItem("Application_Version");
    let applicationVersion = ProjectSettings.getInfo().projectVerison;

    if (currentVersion != applicationVersion) {
      window.localStorage.clear();
      window.localStorage.setItem("Application_Version", applicationVersion);
      this.cookieService.removeAll();
      window.location.reload();
    }

  }

}
